<template>
  <div class="baseInto" ref="info">
      <Card :bordered="true">
         <p slot="title" style="font-size:14px">基本信息区域</p>
        <Form
      ref="formValidate"
      :model="formValidate"
      :label-width="100"
      :label-colon="true"
      :inline="true"
    >
      <FormItem label="信息名称">
        <p>{{formValidate.title !='' ? formValidate.title : '无'}}</p>
      </FormItem>
      <FormItem label="信息来源">
        <p>{{formValidate.source !='' ? formValidate.source : '无'}}</p>
      </FormItem>
      <FormItem label="信息类型" >
        <p>{{formValidate.subtype !='' ? formValidate.subtype : '无'}}</p>
      </FormItem>
      <FormItem label="联系电话">
          <p>{{formValidate.appointmentPhone !='' ? formValidate.appointmentPhone : '无'}}</p>
      </FormItem>
      <FormItem label="公布时间">
        <p>{{formValidate.startTime !='' ? formValidate.startTime :'无'}}</p>
      </FormItem>
      <FormItem label="截至时间">
        <p>{{formValidate.endTime !='' ? formValidate.endTime :'无'}}</p>
      </FormItem>
       <FormItem label="发布范围">
         <p>{{formValidate.city !='' ? formValidate.city :'无'}}</p>
      </FormItem>
      <FormItem label="封面图片" >
         <img ref="image" :src="formValidate.photo" alt=""  width="200" v-if="formValidate.photo">
         <p v-else>无</p>
      </FormItem>
      
       <!-- <iframe name="ueditor" id="ueditor" src="/ueditor/index.html?preview=true&titlename=详情描述"
          style="width:100%;height:500px;border:0px;" @load="loadUeditor"></iframe> -->
          <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=详情描述"
                style="width: 100%; height: 700px; border: 0px"
                @load="loadUeditor"
              ></iframe>
    </Form>
      </Card>
    
  </div>
</template>

<script>
export default {
  props: ["formValidate", "ruleValidate"],
  data() {
    return {
      projectList: [],
    };
  },
  methods: {
    loadUeditor() {
      if(this.formValidate&&this.formValidate.title!=null&&this.formValidate.title!=""){
        var v_Obj=document.getElementById("ueditor").contentWindow;//获取iframe对象
        v_Obj.setContent(this.formValidate.content);//写入编辑器富文本内容
        v_Obj.setPreview(true);
      }else{
       setTimeout(this.loadUeditor,1000);
      }
    },
  },
  created(){
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
  }
};
</script>
    
<style scoped lang='less'>
.ivu-form-item {
  width: 32%
}
/deep/.descItem {
  img {
    max-width: 300px;
    height: auto;
  }
}
</style>